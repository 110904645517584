import React from "react";
import {
	Brand,
	Button,
	TextInput,
	InputGroup,
	Grid,
	GridItem,
	Flex,
	FlexItem,
	PageSection,
	PageSectionVariants,
} from "@patternfly/react-core";
import logo from "../assets/logo.png";
import {
	FacebookIcon,
	TwitterIcon,
	InstagramIcon,
	LinkedinIcon,
	YoutubeIcon,
} from "@patternfly/react-icons";
import { Link } from "react-router-dom";

import config from "../config.json";

const appsUrl = config.appsUrl;
const jobsUrl = config.jobsUrl;
const blogUrl = config.blogUrl;
const labUrl = config.labUrl;
const forumUrl = config.forumUrl;

const Footer = () => {
	const redirectToRegister = () => {
		window.open("https://e2e.llmops.au/jupyter/basics/");
	};
	return (
		<PageSection
			variant={PageSectionVariants.light}
			style={{ background: "#373A40" }}
			className="footer"
		>
			<Grid
				hasGutter
				style={{
					maxWidth: "1440px",
					margin: "0 auto",
					width: "100%",
				}}
			>
				<GridItem span={12} md={4}>
					<Link to="/">
						<Brand
							src={logo}
							alt="PatternFly"
							heights={{
								default: "108px",
							}}
						/>
					</Link>
					<p style={{ color: "#ffffff" }}>
						To be the premier global hub where geotechnical professionals
						connect, innovate, and collaborate, driving advancements in
						engineering solutions and fostering a vibrant community dedicated to
						excellence and sustainability in geotechnics
					</p>

					<Flex>
						<FlexItem>
							<a href="#" aria-label="Facebook">
								<FacebookIcon size="md" />
							</a>
						</FlexItem>
						<FlexItem>
							<a href="#" aria-label="Twitter">
								<TwitterIcon size="md" />
							</a>
						</FlexItem>
						<FlexItem>
							<a href="#" aria-label="Instagram">
								<InstagramIcon size="md" />
							</a>
						</FlexItem>
						<FlexItem>
							<a
								href="https://www.linkedin.com/company/groundcentral/"
								aria-label="LinkedIn"
							>
								<LinkedinIcon size="md" />
							</a>
						</FlexItem>
						<FlexItem>
							<a href="#" aria-label="YouTube">
								<YoutubeIcon size="md" />
							</a>
						</FlexItem>
					</Flex>
				</GridItem>
				<GridItem span={12} md={4}>
					<Flex>
						<Flex
							direction={{ default: "column" }}
							alignItems={{ default: "alignItemsLeft" }}
							style={{ color: "#ffffff" }}
						>
							<FlexItem>
								<strong>Company</strong>
							</FlexItem>
							<FlexItem>
								<a href={appsUrl} target="_blank" rel="noreferrer">
									App Store
								</a>
							</FlexItem>
							<FlexItem>
								<a href={labUrl}>GC-Lab</a>
							</FlexItem>

							<FlexItem>
								<a href={blogUrl}>Blog</a>
							</FlexItem>
							<FlexItem>
								<a href={forumUrl}>Forum</a>
							</FlexItem>
						</Flex>

						<Flex
							direction={{ default: "column" }}
							alignItems={{ default: "alignItemsLeft" }}
							style={{ color: "#ffffff" }}
						>
							<FlexItem>
								<strong>Help</strong>
							</FlexItem>
							<FlexItem>
								<a href="#">About us</a>
							</FlexItem>
							<FlexItem>
								<a href="#">Contact us</a>
							</FlexItem>
							<FlexItem>
								<a href={jobsUrl}>Jobs</a>
							</FlexItem>
						</Flex>
					</Flex>
				</GridItem>
				<GridItem span={12} md={4}>
					<p style={{ marginBottom: "20px" }}>SUBSCRIBE TO NEWSLETTER</p>
					<InputGroup style={{ display: "block" }}>
						<TextInput
							type="email"
							id="subscribe-email"
							name="subscribe-email"
							aria-label="Subscribe email input"
							placeholder="Enter your email"
						/>
						<Button
							variant="primary"
							id="subscribe-button"
							style={{ marginTop: "20px" }}
							onClick={redirectToRegister}
						>
							Subscribe
						</Button>
					</InputGroup>
				</GridItem>
				<GridItem span={12} md={4}></GridItem>
			</Grid>
		</PageSection>
	);
};

export default Footer;
