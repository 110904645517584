import React, { useState, useEffect } from "react";
import {
	Nav,
	NavItem,
	NavList,
	PageSidebar,
	PageSidebarBody,
} from "@patternfly/react-core";
import config from "../config.json";

const appsUrl = config.appsUrl;
const jobsUrl = config.jobsUrl;
const blogUrl = config.blogUrl;
const labUrl = config.labUrl;
const forumUrl = config.forumUrl;

export default function SideBar() {
	const [activeItem, setActiveItem] = React.useState(1);
	const onNavSelect = (_event, selectedItem) => {
		typeof selectedItem.itemId === "number" &&
			setActiveItem(selectedItem.itemId);
	};
	const [isLargeView, setIsLargeView] = useState(window.innerWidth > 1200);
	useEffect(() => {
		const handleResize = () => {
			setIsLargeView(window.innerWidth > 1200);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const pageNav = (
		<Nav onSelect={onNavSelect}>
			<NavList>
				<NavItem itemId={0} isActive={activeItem === 0} to="#system-panel">
					<a href={appsUrl}>App Store</a>
				</NavItem>
				<NavItem>
					<a href={labUrl}>GC-Lab</a>
				</NavItem>
				<NavItem itemId={1} isActive={activeItem === 1} to="#policy">
					<a href={jobsUrl}>Jobs</a>
				</NavItem>
				<NavItem itemId={2} isActive={activeItem === 2} to="#auth">
					<a href={blogUrl}>Blog</a>
				</NavItem>
				<NavItem itemId={3} isActive={activeItem === 3} to="#network">
					<a href={forumUrl}>Forum</a>
				</NavItem>
				<NavItem itemId={4} isActive={activeItem === 4} to="#server">
					<a href="/about">About Us</a>
				</NavItem>
			</NavList>
		</Nav>
	);

	return (
		<>
			{!isLargeView && (
				<PageSidebar>
					<PageSidebarBody>{pageNav}</PageSidebarBody>
				</PageSidebar>
			)}
		</>
	);
}
