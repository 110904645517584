import React from "react";
import {
	Button,
	PageSection,
	Title,
	Flex,
	FlexItem,
} from "@patternfly/react-core";

const HomeBanners = () => {
	const handleClick = () => {
		window.location.href = "mailto:admin@ground-central.com.au";
	};
	const handleRedirectTo = () => {
		window.open("https://ground-central.llmops.au", "_blank");
	};

	return (
		<PageSection className="home-banner">
			<div
				className="home-banner-content"
				style={{
					maxWidth: "1440px",
					margin: "0 auto",
					width: "100%",
				}}
			>
				<Title headingLevel="h1" className="home-banner-title">
					Connecting the world
				</Title>
				<Title headingLevel="h2" size="4xl">
					<span className="highlight">Collaboration</span> Begins Here
				</Title>
				<Title headingLevel="h2" size="2xl" className="home-banner-subtitle">
					Automatically extract{" "}
					<span className="highlight">construction data</span> and use it for
					reporting & checking
				</Title>
				<Flex style={{ marginTop: " 40px" }}>
					<FlexItem>
						<Button variant="primary" onClick={handleClick}>
							Book a demo
						</Button>
					</FlexItem>
					<FlexItem>
						<Button variant="primary" onClick={handleRedirectTo}>
							Try our Earthworks Chatbot
						</Button>
					</FlexItem>
				</Flex>
			</div>
		</PageSection>
	);
};

export default HomeBanners;
