import React from "react";
import { Grid, GridItem, PageSection, Title } from "@patternfly/react-core";
export default function Video() {
	const iframeStyles = {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		border: "none",
	};
	const containerStyles = {
		position: "relative",
		paddingBottom: "56.25%", // 16:9 aspect ratio
		height: 0,
		overflow: "hidden",
		maxWidth: "100%",
		background: "#000", // Optional: Background color for better contrast
	};
	return (
		<PageSection className="bg-color">
			<Grid
				hasGutter
				style={{
					maxWidth: "1440px",
					margin: "0 auto",
					width: "100%",
				}}
			>
				<GridItem span={12} sm={12} md={6}>
					<div style={containerStyles}>
						<iframe
							style={iframeStyles}
							src="https://www.youtube.com/embed/IERt6QvECL0"
							title="Ground Central PTY LTD"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</GridItem>
				<GridItem span={12} sm={12} md={6}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							height: "100%",
							maxWidth: "75%",
							textAlign: "center",
							margin: "0 auto",
						}}
					>
						<Title
							headingLevel="h2"
							size="3xl"
							style={{ marginBottom: "20px" }}
						>
							““We welcome the geotechnical community from around the world to
							come together to innovate,{" "}
							<span className="highlight">and showcase your best work”.</span>”
						</Title>

						<p>Chin Pang,</p>
						<p>Director of Ground Central Pty Ltd</p>
					</div>
				</GridItem>
			</Grid>
		</PageSection>
	);
}
