import React from "react";
import {
	Grid,
	GridItem,
	PageSection,
	Button,
	List,
	ListItem,
	Title,
	Text,
	TextVariants,
	FlexItem,
	Flex,
} from "@patternfly/react-core";
import BookOpenIcon from "@patternfly/react-icons/dist/esm/icons/book-open-icon";
import { ArrowRightIcon } from "@patternfly/react-icons";
import infoImage from "../assets/info.png";
import config from "../config.json";

const blogUrl = config.blogUrl;

export default function Blog() {
	const goToBlog = () => {
		window.open(blogUrl, "_blank");
	};
	return (
		<PageSection className="bg-color">
			<Grid
				hasGutter
				style={{
					maxWidth: "1440px",
					margin: "0 auto",
					width: "100%",
				}}
			>
				<GridItem span={12} sm={12} md={8}>
					<Title headingLevel="h3" size="2xl">
						Insights from our Blog
					</Title>
				</GridItem>
				<GridItem span={12} sm={12} md={4}>
					<Button
						variant="secondary"
						size="sm"
						className="float-responsive"
						onClick={goToBlog}
					>
						Go to Blog
					</Button>{" "}
				</GridItem>
				<div
					style={{
						textAlign: "right",
						width: "100%",
						fontSize: "12px",
					}}
				>
					Share your view and create a blog post
				</div>

				<GridItem span={12} sm={12} md={4}>
					<img className="" src={infoImage} />
				</GridItem>
				<GridItem span={12} sm={12} md={4}>
					<Text
						component={TextVariants.p}
						style={{ fontSize: "12px", color: "#0D6EFD" }}
					>
						News | Blog
					</Text>
					<Title
						headingLevel="h4"
						size="lg"
						style={{ maxWidth: "70%", marginBottom: "40px" }}
					>
						Ground Central's Appearance on World Stage
					</Title>
					<Button variant="primary" size="sm" onClick={goToBlog}>
						Read more
					</Button>{" "}
				</GridItem>
				<GridItem span={12} sm={12} md={4}>
					<Text
						component={TextVariants.p}
						style={{ fontSize: "12px", marginBottom: "12px" }}
					>
						Newest
					</Text>
					<List isPlain isBordered>
						<ListItem
							icon={<BookOpenIcon />}
							style={{
								backgroundColor: "#fff",
								padding: "12px",
								borderRadius: "4px",
							}}
						>
							<a
								href="#"
								style={{
									textDecoration: "none",
									color: "inherit",
									width: "100%",
								}}
							>
								<Flex
									justifyContent={{
										default: "justifyContentSpaceBetween",
									}}
									alignItems={{ default: "alignItemsCenter" }}
									flexWrap={{ default: "nowrap" }}
								>
									<FlexItem>
										<Text
											component={TextVariants.p}
											style={{
												fontSize: "12px",
												color: "#0D6EFD",
											}}
										>
											AI | Blog
										</Text>
										<Text
											component={TextVariants.p}
											style={{ fontSize: "12px" }}
										>
											Ground Central's Appearance on World Stage
										</Text>
									</FlexItem>
									<FlexItem>
										<ArrowRightIcon
											style={{
												fontSize: "12px",
												color: "#0D6EFD",
											}}
										/>
									</FlexItem>
								</Flex>
							</a>
						</ListItem>
						<ListItem
							icon={<BookOpenIcon />}
							style={{
								backgroundColor: "#fff",
								padding: "12px",
								borderRadius: "4px",
							}}
						>
							<a
								href="#"
								style={{
									textDecoration: "none",
									color: "inherit",
									width: "100%",
								}}
							>
								<Flex
									justifyContent={{
										default: "justifyContentSpaceBetween",
									}}
									alignItems={{ default: "alignItemsCenter" }}
									flexWrap={{ default: "nowrap" }}
								>
									<FlexItem>
										<Text
											component={TextVariants.p}
											style={{
												fontSize: "12px",
												color: "#0D6EFD",
											}}
										>
											Engineering | Blog
										</Text>
										<Text
											component={TextVariants.p}
											style={{ fontSize: "12px" }}
										>
											Five minutes Setting up Plaxis for Python
										</Text>
									</FlexItem>
									<FlexItem>
										<ArrowRightIcon
											style={{
												fontSize: "12px",
												color: "#0D6EFD",
											}}
										/>
									</FlexItem>
								</Flex>
							</a>
						</ListItem>
						<ListItem
							icon={<BookOpenIcon />}
							style={{
								backgroundColor: "#fff",
								padding: "12px",
								borderRadius: "4px",
							}}
						>
							<a
								href="#"
								style={{
									textDecoration: "none",
									color: "inherit",
									width: "100%",
								}}
							>
								<Flex
									justifyContent={{
										default: "justifyContentSpaceBetween",
									}}
									alignItems={{ default: "alignItemsCenter" }}
									flexWrap={{ default: "nowrap" }}
								>
									<FlexItem>
										<Text
											component={TextVariants.p}
											style={{
												fontSize: "12px",
												color: "#0D6EFD",
											}}
										>
											Construction | Blog
										</Text>
										<Text
											component={TextVariants.p}
											style={{ fontSize: "12px" }}
										>
											Ground Central's Appearance on World Stage
										</Text>
									</FlexItem>
									<FlexItem>
										<ArrowRightIcon
											style={{
												fontSize: "12px",
												color: "#0D6EFD",
											}}
										/>
									</FlexItem>
								</Flex>
							</a>
						</ListItem>
					</List>
				</GridItem>
			</Grid>
		</PageSection>
	);
}
