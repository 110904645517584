import React from "react";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardTitle,
	Button,
	Text,
	TextContent,
	PageSection,
	Grid,
	GridItem,
	Flex,
	FlexItem,
	Title,
} from "@patternfly/react-core";
import tool1 from "../assets/tool1.png";
import tool2 from "../assets/tool2.png";
import tool3 from "../assets/tool3.png";
import tool4 from "../assets/tool4.png";
import config from "../config.json";

const appsUrl = config.appsUrl;

const cardsData = [
	{
		image: tool1,
		title: "Foundations",
		description: "We have solutions for complex problems",
	},
	{
		image: tool2,
		title: "Building impact assessments",
		description: "We have solutions for complex problems",
	},
	{
		image: tool3,
		title: "Tunnelling",
		description: "We have solutions for complex problems",
	},
	{
		image: tool4,
		title: "Utility impact assessments",
		description: "We have solutions for complex problems",
	},
];

const CardComponent = ({ image, title, description }) => {
	const goToAppStore = () => {
		window.open(appsUrl, "_blank");
	};
	return (
		<Card style={{ borderRadius: "4px" }}>
			<CardHeader>
				<img
					src={image}
					alt={title}
					style={{ width: "100%", height: "auto", maxWidth: "400px" }}
				/>
			</CardHeader>
			<CardTitle>{title}</CardTitle>
			<CardBody>
				<TextContent>
					<Text>{description}</Text>
				</TextContent>
			</CardBody>
			<CardFooter>
				<Button variant="primary" onClick={goToAppStore}>
					Start Now
				</Button>
			</CardFooter>
		</Card>
	);
};

const Tools = () => (
	<PageSection
		style={{
			backgroundImage: `
        radial-gradient(circle at 100% 20%, rgba(130, 129, 255, .6) 250px, transparent 261px),
        radial-gradient(circle at 10% 80%, rgba(54, 189, 255, .5) 200px, transparent 211px)
    `,
			backgroundRepeat: "no-repeat",
			position: "relative",
		}}
	>
		<Flex
			justifyContent={{ default: "justifyContentSpaceBetween" }}
			style={{
				paddingBottom: "20px",
				maxWidth: "1440px",
				margin: "0 auto",
				width: "100%",
			}}
		>
			<FlexItem>
				<Title headingLevel="h3" size="2xl">
					Tools Developed
				</Title>
			</FlexItem>
			<FlexItem>
				<Title headingLevel="h3">Take your skills to the next level</Title>
				<TextContent>
					<Text className="description-text">
						Practical learning for better engineering delivery. Free for all
						practitioners, administrators and leaders.
					</Text>
				</TextContent>
			</FlexItem>
		</Flex>
		<Grid
			hasGutter
			style={{
				maxWidth: "1440px",
				margin: "0 auto",
				width: "100%",
			}}
		>
			{cardsData.map((card, index) => (
				<GridItem key={index} sm={6} md={6} lg={3}>
					<CardComponent {...card} />
				</GridItem>
			))}
		</Grid>
	</PageSection>
);

export default Tools;
